
.club__team-container{
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: var(--color-mid-pink) ;
}

.club__tean-headline{
    /* background: red; */
}

.club__team-memberContainer{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap:20px;

    /* background: lightcoral; */
    padding: 20px;
}

@media screen and (max-width: 1024px){
    .club__team-memberContainer{
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (max-width: 780px){
    .club__team-memberContainer{
        grid-template-columns: repeat(2,1fr);
    }
}

@media screen and (max-width: 500px){
    .club__team-memberContainer{
        grid-template-columns: repeat(1,1fr);
    }
}
