
.recentEvent__component{
    background: var(--color-hot-pink) ;
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    gap: 20px;
    border-bottom: solid 2px black;
    cursor: pointer;

    position: relative;

}

.recentEvent__component a{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    text-decoration: none;
    z-index: 5;
    opacity: 0;
}



.recentEvent__component:hover{
    scale:1.02;
}

.recentEvent-headline{
    /* background: lightsalmon; */

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}