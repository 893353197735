
.club__event-container{
    background: var(--color-light-blue);
    display: flex;
    flex-direction: column;

    gap:10px
}



.club__event-content {
    /* background: lightgray; */
    
    width: 75vw;
    /* height: 150px; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:1.5rem;
}

.club__event-upcoming-event{
    /* background: red; */
    width: 60%;
    height: 500px;
    position: relative;
    border-radius: 4rem;
}

.club__event-upcoming-event img{
    width: 100%;
    height: inherit;
    border-radius: 2rem;
}

.upcomingEvent-btn{
    position: absolute;
    bottom:35px;
    left: 50px;
}

.club__event-recent-event{
    width: 35%;
    height: inherit;
    padding: 0rem 1rem 2rem 1rem;

    display: flex;
    flex-direction: column;
    gap: 1rem; 
}

.recent-event-headline h1{
    font-family: var(--font-base),sans-serif;
    font-weight: 400;
    font-size: 50px;
    text-align: center;
}

.recent-event-content{
    background: var(--color-hot-pink);
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 1em;
}

@media  screen and (max-width: 1024px){
    .club__event-content {
        width: 100%;
        flex-direction: column;
        
    }
    .club__event-upcoming-event{
        width: 100%;
    }

    .club__event-recent-event{
        width: 100%;
        /* padding: 0rem; */
        /* height: 400px; */
    }
}

@media  screen and (max-width: 450px){

    
}